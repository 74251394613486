import Vue from 'vue'
import App from './App.vue'

import axios from 'axios'
import VueAxios from 'vue-axios'

import moment from 'moment'

// the amplify auth modules only
import { Logger } from '@aws-amplify/core'
import { I18n } from '@aws-amplify/core'
import { Auth } from '@aws-amplify/auth'
import { Storage } from '@aws-amplify/storage'
import { PubSub } from '@aws-amplify/pubsub'
import AmplifyPlugin from './plugins/amplify'

import store from './store'
import router from './router'

import vuetify from './plugins/vuetify'
import videon from './plugins/videon'

import helpers from './plugins/helpers'
import validations from './plugins/validations'
import versioning from './plugins/versioning'

import capabilities from './objects/Capabilities.js'

import organizations from './objects/Organizations.js'
import devices from './objects/Devices.js'
import deviceShadows from './objects/DeviceShadows.js'
import fleets from './objects/Fleets.js'
import users from './objects/Users.js'
import alerts from './objects/Alerts.js'

import entitlementProfiles from './objects/EntitlementProfiles.js'

import containers from './objects/Containers.js'

import latestVersions from './objects/LatestVersions.js'


Vue.use(AmplifyPlugin, { Auth, Storage, Logger, I18n, PubSub })
Vue.config.productionTip = false

Vue.use(VueAxios, axios)

Vue.use({
  install() {
    Vue.helpers = helpers
    Vue.prototype.$helpers = helpers
    
    Vue.validations = validations
    Vue.prototype.$validations = validations
    
    Vue.versioning = versioning
    Vue.prototype.$versioning = versioning
    
    Vue.capabilities = Vue.observable(capabilities)
    Vue.prototype.$capabilities = Vue.capabilities
    
    Vue.organizations = Vue.observable(organizations)
    Vue.prototype.$organizations = Vue.organizations
    
    Vue.devices = Vue.observable(devices)
    Vue.prototype.$devices = Vue.devices
    
    Vue.deviceShadows = Vue.observable(deviceShadows)
    Vue.prototype.$deviceShadows = Vue.deviceShadows
    
    Vue.fleets = Vue.observable(fleets)
    Vue.prototype.$fleets = Vue.fleets
    
    Vue.users = Vue.observable(users)
    Vue.prototype.$users = Vue.users
    
    Vue.alerts = Vue.observable(alerts)
    Vue.prototype.$alerts = Vue.alerts
    
    Vue.entitlementProfiles = Vue.observable(entitlementProfiles)
    Vue.prototype.$entitlementProfiles = Vue.entitlementProfiles

    Vue.containers = Vue.observable(containers)
    Vue.prototype.$containers = Vue.containers
    
    Vue.latestVersions = Vue.observable(latestVersions)
    Vue.prototype.$latestVersions = Vue.latestVersions
  }
})



// this is better than the vue-moment plugin...
Vue.filter('moment', function (value, format) {
  if (value === null || value === undefined || value == '' || format === undefined) {
    return ''
  }
  if (format === 'from') {
    return moment(value).fromNow()
  }
  return moment(value).format(format)
})


// set document title
var title = store.getters['config/deploymentName']
document.title = title

new Vue({
  store,
  router,
  vuetify,
  videon,
  helpers,
  render: h => h(App)
}).$mount('#app')
