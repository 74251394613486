<template>
  <div>
    <v-dialog
      v-model="showCreateContainer"
      max-width="640px"
    >
      <videon-card heading="Create Container" showClose="true" @close="showCreateContainer = false">
        <v-card-text>
          <v-form @submit.prevent="createContainer">
            <v-text-field
              v-model="createContainerName"
              label="Container Name"
              autocomplete="off"
              :rules="$validations.containerNameValidation"
              required
              outlined
            ></v-text-field>
            
            <v-select
              v-model="createContainerImageID"
              :items="images"
              itemValue="id"
              label="Container Image"
              hide-details
              outlined
            >
              <template slot="selection" slot-scope="data">
                {{ $helpers.repositoryName(data.item) }}
              </template>
              <template slot="item" slot-scope="data">
                {{ $helpers.repositoryName(data.item) }}
              </template>
            </v-select>
            
            <edit-manifest
              v-model="createContainerManifest"
              :isAdmin="isAdmin"
            ></edit-manifest>
            
            <div class="text-right mt-5">
              <v-btn
                class="ma-1"
                color="secondary"
                @click="showCreateContainer = false"
                text
              >
                Cancel
              </v-btn>
              <v-btn
                class="ma-1"
                color="primary"
                @click="createContainer"
                :disabled="!isCreateContainerValid"
              >
                Create Container
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </videon-card>
    </v-dialog>
    
    <v-dialog
      v-model="showConfirmKillContainer"
      max-width="480px"
    >
      <videon-card heading="Kill Container" showClose="true" @close="showConfirmKillContainer = false">
        <v-card-text>
          <p class="subtitle-1 mt-3 mb-5">
            Are you sure you want to kill <span class="primary--text">{{ selectedContainer.container_id && $containers.getContainer(selectedContainer.container_id).name || selectedContainer.name }}</span>?
          </p>
          <div class="text-right">
            <v-btn
              class="ma-1"
              color="secondary"
              @click="showConfirmKillContainer = false"
              text
            >
              Cancel
            </v-btn>
            <v-btn
              class="ma-1"
              color="primary"
              @click="confirmKillContainer"
            >
              Kill
            </v-btn>
          </div>
        </v-card-text>
      </videon-card>
    </v-dialog>
    
    <v-dialog
      v-model="showConfirmDeleteContainer"
      max-width="480px"
    >
      <videon-card heading="Delete Container" showClose="true" @close="showConfirmDeleteContainer = false">
        <v-card-text>
          <p class="subtitle-1 mt-3 mb-5">
            Are you sure you want to delete <span class="primary--text">{{ selectedContainer.container_id && $containers.getContainer(selectedContainer.container_id).name || selectedContainer.name }}</span>?
          </p>
          <div class="text-right">
            <v-btn
              class="ma-1"
              color="secondary"
              @click="showConfirmDeleteContainer = false"
              text
            >
              Cancel
            </v-btn>
            <v-btn
              class="ma-1"
              color="primary"
              @click="confirmDeleteContainer"
            >
              Delete
            </v-btn>
          </div>
        </v-card-text>
      </videon-card>
    </v-dialog>
    
    <v-card flat>
      <v-card-title>
        Installed Containers
        
        <v-spacer/>
        
        <v-btn
          small
          outlined
          class="mr-3"
          color="secondary"
          @click="refreshContainers"
          tooltip="Reload Containers"
          :disabled="loading"
        >
          Reload
        </v-btn>
        
        <v-btn
          small
          color="primary"
          @click="showCreateContainer = true"
          tooltip="Create Container"
          :disabled="!isOnline || isProcessing"
        >
          <v-icon>mdi-plus</v-icon> Create Container
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="containerHeaders"
          :items="containers"
          hide-default-footer
          disable-pagination
          :loading="loading"
          :items-per-page="200"
        >
          <template v-slot:item.id="{ item }">
            <div class="text-truncate" style="max-width: 110px;">
               {{ item.id }}
            </div>
          </template>
          <template v-slot:item.name="{ item }">
            <div class="text-truncate" style="max-width: 200px;">
               {{ item.container_id && $containers.getContainer(item.container_id).name || item.name }}
            </div>
          </template>
          <template v-slot:item.image="{ item }">
            <span v-if="containerImageName(item)">{{ containerImageName(item) }}</span>
            <div v-else class="text-truncate text-center" style="max-width: 200px;">
               {{ item.image }}
            </div>
          </template>
          <template v-slot:item.actions="{ item }">
            <div v-if="actionedContainer.id == item.id">
              <v-progress-linear
                color="black"
                indeterminate
                buffer-value="0"
              ></v-progress-linear>
            </div>
            <div v-else class="text-right">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    text
                    small
                    @click="startStopContainer(item)"
                    :disabled="item.status == 'paused' || !isOnline || isProcessing || isProtectedContainer(item)"
                  >
                    <v-icon v-if="startStopAction(item) == 'start'">mdi-play</v-icon>
                    <v-icon v-else>mdi-stop</v-icon>
                  </v-btn>
                </template>
                <span>Stop/Start Container</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    text
                    small
                    @click="pauseUnpauseContainer(item)"
                    :disabled="pauseUnpauseAction(item) == 'disabled' || !isOnline || isProcessing || isProtectedContainer(item)"
                  >
                    <v-icon v-if="pauseUnpauseAction(item) == 'running'">mdi-pause</v-icon>
                    <v-icon v-if="pauseUnpauseAction(item) == 'unpause'">mdi-play-pause</v-icon>
                    <v-icon v-else>mdi-pause</v-icon>
                  </v-btn>
                </template>
                <span>Pause/Resume Container</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    text
                    small
                    @click="restartContainer(item)"
                    :disabled="item.status != 'running' || !isOnline || isProcessing"
                  >
                    <v-icon>mdi-restart</v-icon>
                  </v-btn>
                </template>
                <span>Restart Container</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    text
                    small
                    @click="killContainer(item)"
                    :disabled="(item.status != 'running' && item.status != 'restarting') || !isOnline || isProcessing || isProtectedContainer(item)"
                  >
                    <v-icon>mdi-skull</v-icon>
                  </v-btn>
                </template>
                <span>Kill Container</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    text
                    small
                    @click="deleteContainer(item)"
                    :disabled="(item.status != 'exited' && item.status != 'created') || !isOnline || isProcessing || isProtectedContainer(item)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Delete Container</span>
              </v-tooltip>
            </div>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
  import EditManifest from '../../../container/components/EditManifest.vue'

  export default {
    name: 'DockerContainersList',

    props: [
      'loading',
      'isAdmin',
      'isOnline',
      'isProcessing',
      'actionedContainer',
      'images',
      'containers'
    ],

    components: {
      EditManifest
    },
    
    data() {
      return {
        noContainers: false,
        
        showCreateContainer: false,
        
        selectedContainer: false,
        
        showConfirmKillContainer: false,
        showConfirmDeleteContainer: false,
        
        protectedContainerNames: [
          'videon-fans',
          'videon-cloud',
          'videon-api-app',
          'videon-api-proxy'
        ],
        
        containerHeaders: [
          { text: 'Container ID', align: 'start', value: 'id', sortable: false },
          { text: 'Name', align: 'start', value: 'name', sortable: false },
          { text: 'Image', align: 'start', value: 'image', sortable: false },
          { text: 'Status', align: 'center', value: 'status', sortable: false },
          { text: '', align: 'end', value: 'actions', sortable: false, width: '300px' },
        ],
        
        createContainerName: '',
        createContainerImageID: '',
        createContainerManifest: null
      }
    },

    computed: {
      isCreateContainerValid() {
        if (this.$validations.isValidContainerName(this.createContainerName)) {
          return true
        }
        
        return false
      }
    },

    methods: {
      containerImageName(item) {
        var image = this.images.find(x => x.id === item.image)
        if (image) {
          return this.$helpers.repositoryName(image)
        }
        return false
      },
      
      isProtectedContainer(item) {
        if (item.name && this.protectedContainerNames.includes(item.name)) {
          return true
        }
        return false
      },
      
      createContainer() {
        // ensure the 2 required items are valid
        if (!this.createContainerImageID || !this.createContainerName) {
          return
        }
        
        this.showCreateContainer = false
        
        if (
          this.createContainerManifest &&
          this.createContainerManifest.ports &&
          this.createContainerManifest.ports.length > 0
        ) {
          for (var port of this.createContainerManifest.ports) {
            if (!port.host_port || !port.container_port) {
              // TODO: Display an error message
              return
            }
          }
        }
        
        if (
          this.createContainerManifest &&
          this.createContainerManifest.environment &&
          this.createContainerManifest.environment.length > 0
        ) {
          for (var envVar of this.createContainerManifest.environment) {
            if (!envVar.key) {
              // TODO: Display an error message
              return
            }
          }
        }
        
        if (
          this.createContainerManifest &&
          this.createContainerManifest.volumes &&
          this.createContainerManifest.volumes.length > 0
        ) {
          for (var volume of this.createContainerManifest.volumes) {
            if (!volume.host_path || !volume.container_path || !volume.mode) {
              // TODO: Display an error message
              return
            }
          }
        }
        
        if (
          this.createContainerManifest &&
          this.createContainerManifest.devices &&
          this.createContainerManifest.devices.length > 0
        ) {
          for (var device of this.createContainerManifest.devices) {
            if (!device.host_path || !device.container_path || !device.mode) {
              // TODO: Display an error message
              return
            }
          }
        }
        
        this.$emit('create', {
          name: this.createContainerName,
          imageId: this.createContainerImageID,
          ...this.createContainerManifest
        })
      },
      
      confirmDeleteContainer() {
        this.showConfirmDeleteContainer = false
        this.$emit('delete', this.selectedContainer)
      },
      
      refreshContainers() {
        this.$emit("refresh")
      },
      
      // button states
      startStopAction(item) {
        if (item.status == 'running' || item.status == 'restarting') {
          return 'stop'
        }
        return 'start'
      },
      
      pauseUnpauseAction(item) {
        if (item.status == 'paused') {
          return 'unpause'
        } else if (item.status == 'running') {
          return 'pause'
        }
        return 'disabled'
      },
      
      killContainer(item) {
        console.log('DockerContainersList killContainer', item)
        this.selectedContainer = item
        this.showConfirmKillContainer = true
      },
      
      deleteContainer(item) {
        console.log('DockerContainersList deleteContainer', item)
        
        this.selectedContainer = item
        this.showConfirmDeleteContainer = true
      },

      // button actions
      startStopContainer(item) {
        this.$emit('startStop', item)
      },
      
      pauseUnpauseContainer(item) {
        this.$emit('pauseUnpause', item)
      },
      
      confirmKillContainer() {
        this.showConfirmKillContainer = false
        this.$emit('kill', this.selectedContainer)
      },
      
      restartContainer(item) {
        this.$emit('restart', item)
      },
    }
  }
</script>