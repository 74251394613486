<template>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
  > 
    <div v-if="config.name !== undefined || !id" class="mb-5">
      <v-row><h5 class="text--disabled">Input</h5></v-row>
      <v-row class="mb-3"><v-divider /></v-row>
      <v-row class="mb-3">
        <v-col cols="12">
          <v-text-field
            v-model="config.name"
            label="Input Name"
            @input="configChanged"
            :disabled="!canEdit"
            outlined
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
    </div>
    
    <v-row><h5 class="text--disabled">Input Settings</h5></v-row>
    <v-row class="mb-3"><v-divider /></v-row>
    <v-row>
      <v-col cols="7">
        <v-select
          v-model="config.video_input_type"
          :items="video_inputValues"
          label="Video Input"
          @change="configChanged"
          :disabled="!canEdit"
          outlined
          hide-details
        ></v-select>
      </v-col>
      <v-col cols="5">
        <v-select
          v-model="config.video_rotation"
          :items="video_rotationValues"
          label="Video Input Rotation"
          @change="configChanged"
          :disabled="!canEdit"
          outlined
          hide-details
        ></v-select>
      </v-col>
    </v-row>
    <v-row class="mb-3">
      <v-col cols="12">
        <v-select
          v-model="config.audio_input_type"
          :items="audio_inputValues"
          label="Audio Input"
          @change="configChanged"
          :disabled="!canEdit"
          outlined
          hide-details
        ></v-select>
      </v-col>
    </v-row>
    
    <v-row><h5 class="text--disabled">Graphic Overlay</h5></v-row>
    <v-row class=""><v-divider /></v-row>
    <v-row >
      <v-col cols="12">
        <v-checkbox
          v-model="config.graphic_overlay.enable"
          label="Enable Graphic Overlay"
          @change="configChanged"
          :disabled="!canEdit"
          hide-details="auto"
          class="mt-0"
        ></v-checkbox>
      </v-col>
    </v-row>
    <div v-if="config.graphic_overlay.enable">
      <v-row>
        <v-col cols="12">
          <v-alert
            type="info"
            text
            icon="mdi-lock-outline"
            class="mt-3"
          >
            Graphic Overlay images must be managed directly on the device at this time.
          </v-alert>
        </v-col>
      </v-row>
      <v-row class="mb-3">
        <v-col cols="6">
          <v-select
            v-model="config.graphic_overlay.preset_location"
            :items="graphic_overlay_preset_locationValues"
            label="Overlay Position"
            @change="configChanged"
            :disabled="!canEdit"
            hide-details="auto"
            outlined
          ></v-select>
        </v-col>
      </v-row>
      
      <div v-if="config.graphic_overlay.preset_location == 'MANUAL'">
        <v-row class="mb-3">
          <v-col cols="3">
            <v-text-field
              v-model.number="config.graphic_overlay.x_position"
              label="Image X Position %"
              @input="configChanged"
              :disabled="!canEdit"
              type="number"
              :rules="$validations.percent"
              hide-details="auto"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model.number="config.graphic_overlay.y_position"
              label="Image Y Position %"
              @input="configChanged"
              :disabled="!canEdit"
              type="number"
              :rules="$validations.percent"
              hide-details="auto"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
      </div>
      
      <div v-if="config.graphic_overlay.preset_location != 'FULLSCREEN' && config.graphic_overlay.preset_location != 'LOWER_THIRD'">
        <v-row class="mt-3">
          <h5 class="text--disabled">Graphic Overlay Image Scaling</h5>
          <v-spacer/>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                text
                small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon small>question_mark</v-icon>
              </v-btn>
            </template>
            <span style="line-height: 1.5em">
              When 'Lock Aspect Ratio' is configured to width or height scaling will be done automatically to keep the original images aspect ratio.<br/>
              Image Width % and Image Height % represent the % of input stream covered by the overlay image.<br/>
              Example: With an output resolution 1920x1080p when the Image Width % is set to 100% the overlay image will be 1920 pixels wide.
            </span>
          </v-tooltip>
        </v-row>
        <v-row class="mb-3"><v-divider /></v-row>
        <v-row class="mb-3">
          <v-col cols="6">
            <v-select
              v-model="config.graphic_overlay.aspect_ratio_lock"
              :items="graphic_overlay_aspect_ratio_lockValues"
              label="Lock Aspect Ratio"
              @change="configChanged"
              :disabled="!canEdit"
              hide-details="auto"
              outlined
            ></v-select>
          </v-col>
        </v-row>
        <v-row class="mb-3">
          <v-col cols="3" v-if="config.graphic_overlay.aspect_ratio_lock == 'NO_LOCK' || config.graphic_overlay.aspect_ratio_lock == 'LOCK_TO_WIDTH'">
            <v-text-field
              v-model.number="config.graphic_overlay.width"
              label="Image Width %"
              @input="configChanged"
              :disabled="!canEdit"
              type="number"
              :rules="$validations.percent"
              hide-details="auto"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="3" v-if="config.graphic_overlay.aspect_ratio_lock == 'NO_LOCK' || config.graphic_overlay.aspect_ratio_lock == 'LOCK_TO_HEIGHT'">
            <v-text-field
              v-model.number="config.graphic_overlay.height"
              label="Image Height %"
              @input="configChanged"
              :disabled="!canEdit"
              type="number"
              :rules="$validations.percent"
              hide-details="auto"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
      </div>
      <div v-if="config.graphic_overlay.preset_location == 'LOWER_THIRD'">
        <v-row><h5 class="text--disabled">Graphic Overlay Image Scaling</h5></v-row>
        <v-row class="mb-3"><v-divider /></v-row>
        <v-row class="mb-3">
          <v-col cols="3">
            <v-text-field
              v-model.number="config.graphic_overlay.height"
              label="Image Height %"
              @input="configChanged"
              :disabled="!canEdit"
              type="number"
              :rules="$validations.percent"
              hide-details="auto"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
      </div>
    </div>
    
    <div v-if="id && showPreview && validInput" class="mt-5">
      <v-row><h5 class="text--disabled">AV Input Preview</h5></v-row>
      <v-row class="mb-3"><v-divider /></v-row>
      <v-row class="mb-3">
        <v-col v-if="thumbnailEnabled" cols="12">
          <input-preview :device_guid="device_guid" :input_id="id" />
        </v-col>
        <v-col v-else cols="12">
          <v-alert
            type="info"
            text
            class="mt-3"
          >
            To utilize input preview, please ensure a running thumbnail output for this input.
          </v-alert>
        </v-col>
      </v-row>
    </div>
    
    <div v-if="id && !isNew && shadowDebug" class="mt-5">
      <v-row><h5 class="text--disabled">Input Details</h5></v-row>
      <v-row class="mb-3"><v-divider /></v-row>
      <v-row class="mb-3">
        <v-col cols="12">
          <pre style="inline-size: 640px; white-space: pre-wrap;">{{ input }}</pre>
        </v-col>
      </v-row>
    </div>
    
    <div v-if="id && !isNew && canEdit" class="text-right">
      <v-btn
        class="ma-1 d-none d-md-inline"
        x-small
        outlined
        @click="deleteInput()"
      >
        Delete Input
      </v-btn>
    </div>
  </v-form>
</template>
<script>
  import { mapGetters } from 'vuex'
  import InputPreview from '../../../components/InputPreview.vue'

  export default {
    name: 'InputsDocument',
    
    props: ['input', 'device_guid', 'canEdit', 'isNew'],
    
    components: {
      InputPreview
    },
    
    data() {
      return {
        valid: true,
        showPreview: true,
        
        id: '',
        audio_input_detected: false,
        video_input_format: '',
        
        video_rotationValues: [
          { 'value': 'ROTATE_0', 'text': 'None' },
          { 'value': 'ROTATE_90', 'text': '90°'},
          { 'value': 'ROTATE_180', 'text': '180°'},
          { 'value': 'ROTATE_270', 'text': '270°'}
        ],
        
        graphic_overlay_aspect_ratio_lockValues: [
          { 'value': 'NO_LOCK', 'text': 'No Lock' },
          { 'value': 'LOCK_TO_WIDTH', 'text': 'Lock to Image Width' },
          { 'value': 'LOCK_TO_HEIGHT', 'text': 'Lock to Image Height' }
        ],
        
        graphic_overlay_preset_locationValues: [
          { 'value': 'MANUAL', 'text': 'Manual' },
          { 'value': 'FULLSCREEN', 'text': 'Full Screen' },
          { 'value': 'LOWER_THIRD', 'text': 'Lower 1/3' },
          { 'value': 'TOP_LEFT', 'text': 'Top Left Corner' },
          { 'value': 'BOTTOM_LEFT', 'text': 'Bottom Left Corner' },
          { 'value': 'TOP_CENTER', 'text': 'Center Top' },
          { 'value': 'CENTER', 'text': 'Center' },
          { 'value': 'BOTTOM_CENTER', 'text': 'Center Bottom' },
          { 'value': 'TOP_RIGHT', 'text': 'Top Right Corner' },
          { 'value': 'BOTTOM_RIGHT', 'text': 'Bottom Right Corner' }
        ],
        
        config: {
          video_input_type: 'input_auto',
          video_rotation: 'ROTATE_0',
          audio_input_type: 'input_auto',
        
          graphic_overlay: {
            'enable': false,
            'x_position': 0,
            'y_position': 0,
            'width': 100,
            'height': 100,
            'aspect_ratio_lock': 'NO_LOCK',
            'preset_location': 'MANUAL'
          },
        }
      }
    },
    
    watch: {
      input() {
        this.updateValues()
      },
    },
    
    computed: {
      ...mapGetters('userPreferences', ['shadowDebug']),
      
      device() {
        return this.$devices.getDevice(this.device_guid)
      },
      
      
      video_inputValues() {
        var inputValues = [
          { 'value': 'input_auto', 'text': 'Auto Detect' },
          { 'value': 'input_sdi', 'text': 'SDI Video' },
          { 'value': 'input_hdmi', 'text': 'HDMI Video' },
        ]
        
        if (this.device.product_name == 'edgecaster_max') {
          inputValues.push({ 'value': 'input_tpg', 'text': 'Test Pattern Generator' })
        }
        
        return inputValues
      },
      
      audio_inputValues() {
        var inputValues =  [
          { 'value': 'input_embedded', 'text': 'Embedded Audio' },
          { 'value': 'input_3p5mm', 'text': '3.5mm Audio' },
          { 'value': 'input_auto', 'text': 'Auto Detect' }
        ]
        
        if (this.device.product_name == 'edgecaster_max') {
          inputValues.push({ 'value': 'input_tpg', 'text': 'Test Pattern Generator' })
        }
        
        return inputValues
      },
      
      thumbnailEnabled() {
        return this.device.thumbnailEnabled()
      },
      
      validInput() {
        return (this.video_input_format == 'FORMAT_UNDEFINED') ? false : true
      }
    },
    
    mounted() {
      this.updateValues()
    },
    
    methods: {
      configChanged() {
        var validationErrors = false

        // check for validation errors
        if (!this.$refs.form.validate()) {
          console.log('InputsDocument ' + this.device_guid + ' validation error: Percentages must be between 0 and 100', this.config)
          validationErrors = true
        }

        console.log('InputsDocument ' + this.device_guid + ' configChanged', this.config)
        this.$emit('configUpdate', this.config, validationErrors)
      },
      
      updateValues() {
        if (this.input && this.input.config) {
          Object.keys(this.input).map((key) => {
            this[key] = this.input[key]
          })
          
          // htmlDecode name properties
          if (this.config.name !== undefined) {
            this.config.name = this.$helpers.htmlDecode(this.config.name)
          }
        }
        
        this.configChanged()
      },
      
      deleteInput() {
        console.log('InputsDocument ' + this.device_guid + ' deleteInput')
        this.$emit('deleteInput')
      }
    }
  }
</script>