<template>
  <div>
    <v-dialog
      v-model="showPullImage"
      max-width="480px"
    >
      <videon-card heading="Pull Image" showClose="true" @close="showPullImage = false">
        <v-card-text>
          <v-form @submit.prevent="pullImage">
            <v-text-field
              v-model="pullImageRepository"
              label="Image Repository"
              autocomplete="off"
              required
              outlined
            ></v-text-field>
            <v-text-field
              v-model="pullImageTag"
              label="Tag"
              autocomplete="off"
              required
              outlined
            ></v-text-field>
            
            <v-text-field
              v-model="pullImageUser"
              label="Repository User (optional)"
              autocomplete="off"
              outlined
            ></v-text-field>
            <v-text-field
              v-model="pullImagePassword"
              v-if="pullImageUser"
              label="Repository Password"
              type="password"
              autocomplete="off"
              outlined
            ></v-text-field>
            <div class="text-right">
              <v-btn
                class="ma-1"
                color="secondary"
                @click="showPullImage = false"
                text
              >
                Cancel
              </v-btn>
              <v-btn
                class="ma-1"
                color="primary"
                @click="pullImage"
              >
                Pull Image
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </videon-card>
    </v-dialog>
    
    <v-dialog
      v-model="showDeleteImage"
      max-width="480px"
    >
      <videon-card heading="Delete Image" showClose="true" @close="showDeleteImage = false">
        <v-card-text>
          <p class="subtitle-1 mt-3 mb-5">
            Are you sure you want to delete <span class="primary--text">{{ $helpers.repositoryName(selectedImage) }}</span>?
          </p>
          <div class="text-right">
            <v-btn
              class="ma-1"
              color="secondary"
              @click="showDeleteImage = false"
              text
            >
              Cancel
            </v-btn>
            <v-btn
              class="ma-1"
              color="primary"
              @click="confirmDeleteImage"
            >
              Delete
            </v-btn>
          </div>
        </v-card-text>
      </videon-card>
    </v-dialog>
    
    <v-card flat>
      <v-card-title>
        Available Images
        <v-spacer/>
        <v-btn
          small
          color="accent"
          @click="showPullImage = true"
          tooltip="Pull Image"
          :disabled="!isOnline || isProcessing"
        >
          <v-icon>mdi-plus</v-icon> Pull Image
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="imageHeaders"
          :items="images"
          :items-per-page="50"
          :loading="loading"
        >
          <template v-slot:item.tags="{ item }">
            <div v-if="item.tags && item.tags[0]">
               {{ $helpers.repositoryName(item) }}
            </div>
            <div v-else class="text--disabled">
              &lt;none&gt;
            </div>
          </template>
          <template v-slot:item.id="{ item }">
            <div class="text-truncate" style="max-width: 110px;">
               {{ item.id }}
            </div>
          </template>
          <template v-slot:item.created="{ item }">
              {{ item.created | moment("YYYY/MM/DD hh:mm:ss a") }}
          </template>
          <template v-slot:item.size="{ item }">
              {{ $helpers.byteFormat(item.size) }}
          </template>
          <template v-slot:item.actions="{ item }">
            <div class="text-right">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    text
                    small
                    @click="rePullImage(item)"
                    :disabled="!isOnline || isProcessing"
                  >
                    <v-icon>mdi-download</v-icon>
                  </v-btn>
                </template>
                <span>Pull Image</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    text
                    small
                    @click="deleteImage(item)"
                    :disabled="!isOnline || isProcessing || isProtectedImage(item)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Delete Image</span>
              </v-tooltip>
            </div>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
  export default {
    name: 'DockerImagesList',
    
    props: ['isAdmin', 'isOnline', 'isProcessing', 'loading', 'images'],
    
    data() {
      return {
        selectedImage: false,
        showPullImage: false,
        showDeleteImage: false,
        
        protectedImageNames: [
          'spyro-lcd-menu:stable-latest',
          'spyro-daemon:stable-latest',
          'spyro-xml:stable-latest',
          'spyro-api-app:stable-latest',
          'spyro-cloud:stable-latest',
          'spyro-api-proxy:stable-latest',
          'spyro-fans:stable-latest'
        ],
        
        imageHeaders: [
          { text: 'Image ID', align: 'start', value: 'id', sortable: false },
          { text: 'Image', align: 'start', value: 'tags', sortable: false },
          { text: 'Created', align: 'center', value: 'created', sortable: false },
          { text: 'Size', align: 'center', value: 'size', sortable: false },
          { text: '', align: 'end', value: 'actions', sortable: false },
        ],

        pullImageRepository: '',
        pullImageTag: 'latest',
        pullImageUser: '',
        pullImagePassword: ''
      }
    },
    
    methods: {
      isProtectedImage(item) {
        if (this.protectedImageNames.includes(this.$helpers.repositoryName(item))) {
          return true
        }
        return false
      },
      
      pullImage() {
        if (!this.pullImageRepository || !this.pullImageTag) {
          return
        }
        
        this.showPullImage = false
        
        this.$emit('pull', {
          repository: this.pullImageRepository,
          tag: this.pullImageTag,
          username: this.pullImageUser,
          password: this.pullImagePassword
        })
      },
      
      rePullImage(item) {
        console.log('DockerImagesList rePullImage', item)
        
        if (item && item.tags && item.tags[0]) {
          var splitItem = item.tags[0].split(':')
          var itemName = splitItem[0]
          var itemTag = splitItem[1]
          
          this.pullImageRepository = itemName
          this.pullImageTag = itemTag
          
          this.showPullImage = true
        }
      },
      
      deleteImage(item) {
        console.log('DockerImagesList deleteImage', item)
        this.selectedImage = item
        this.showDeleteImage = true
      },
      
      confirmDeleteImage() {
        this.showDeleteImage = false
        this.$emit('delete', this.selectedImage)
      }
    }
  }
</script>
